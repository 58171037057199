import {
  AnalysisAppliedFiltersModel,
  AnalysisAppliedFilterValuesModel,
  AnalysisBenchmarkModel,
  AnalysisFilterModel,
  AnalysisFilterOptionParamsModel,
  AnalysisIndicatorModel
} from 'pages/analysis/models';
import { AnalysisDataService } from '../analysis-data/analysis-data.service';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import { AnalysisIndicatorService } from '../analysis-indicator/analysis-indicator.service';
import {
  AnalysisStateConfig,
  AnalysisStateFiltersModel,
  AnalysisStateIndicatorsModel,
  AnalysisStateModel,
  ChartSettingsModel,
  RequestParamsModel,
} from 'pages/analysis/models/analysis-state.model';
import { AnalysisEntityService } from 'components/common/services/analysis-entity/analysis-entity.service';
import { ChartOptionsModel } from '@ic/charts/src/models/chart.model';
import { ChartOptionsService } from '../chart-options/chart-options.service';
import { DatasetModel } from 'components/rest/services/datasets-repository/models';
import { EntitiesRepositoryService } from 'components/rest/services/entities-repository/entities-repository.service';
import { EntityModel } from 'components/rest/services/entities-repository/entity.model';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { ExploreTileResponseModel} from 'components/common/interfaces/exploretile.response.model';
import {find, union, clone, isEqual, isUndefined, assign, uniq, pick, intersection, cloneDeep, isArray} from 'lodash';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import {Observable, ReplaySubject, Subject, forkJoin, of, Subscription} from 'rxjs';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { SerializedExploreStateModel } from 'components/analytics/interfaces/expore-state.interface';
import { SettingsRepositoryService } from 'components/rest/services/settings-repository/settings-repository.service';
import { SortableTableRowModel } from '@ic/component-lib/src/components/modules/sortable-table/models/sortable-table-row.model';
import { StateParams, StateService } from '@uirouter/core';
import { UserStorageService } from 'components/analytics/services/user-storage/user-storage.service';
import { VisualizationModel } from 'components/diagram/interfaces/diagram-options.model';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import {ExploreRequestModel} from 'components/common/interfaces/explore.request.model';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import {TogglesState} from '@wos/toggles-core';
import { FullFilterValueModel } from 'components/analytics/interfaces/filter.interface';
import { DataRepositoryService } from 'components/rest/services/data-repository/data-repository.service';
import { Globals } from 'components/shared/globalData';
import {UserService} from 'components/auth/services/user/user.service';

@Injectable()
export class AnalysisService implements OnDestroy {
  row = new EventEmitter();
  unHideDeptId = new EventEmitter();

  /* Initial params */
  private datasetId!: string;
  private entityId!: string;
  private reportId?: string;
  private tileId?: string;
  // @ts-ignore used in config
  private filterAggId!: string;
  private queryDataCollection?: string | undefined;
  private _selectedTabIndex: number = 0;

  private _requestParams?: RequestParamsModel;
  private tile?: ReportTileModel;
  private disableSaveTile: boolean = true;
  private state$!: ReplaySubject<AnalysisStateModel>;
  private unlockRank: boolean = false;

  public filterDefaultValue = new Subject<boolean>();

  /* State params */
  private benchmarks!: AnalysisBenchmarkModel[];
  private chartSettings!: ChartSettingsModel;
  public currentEntity!: EntityModel;
  private datasets!: DatasetModel[];
  private entities!: EntityModel[];
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  private filters!: AnalysisStateFiltersModel;
  private indicators!: AnalysisStateIndicatorsModel;

  private columnsIndicators = [] as AnalysisIndicatorModel[];

  /* State streams */

  private exploreChildItems$ = of({}) as Observable<ExploreTileResponseModel>;
  private exploreItems$ = of({}) as Observable<ExploreTileResponseModel>;
  private benchmarksItems$ = of({}) as Observable<ExploreTileResponseModel>;
  private groupsItems$ = of({}) as Observable<ExploreTileResponseModel>;
  private visualizations$!: Observable<VisualizationModel[]>;
  private chartOptions$!: Observable<ChartOptionsModel>;
  private dataUpdateInfo: string = '';
  private toggles: TogglesState;
  issnOrSource: string = this.analysisFiltersService.issnOrSource.getValue() || 'jrnname';
  iSSNorSourceSubscription: Subscription;
  collection!: {};

  get currentDataset(): DatasetModel|undefined {
    if (this.datasets && this.datasets.length > 0 ) {
      return find(this.datasets, {datasetId: this.datasetId});
    }
  }

  get requestParams(): RequestParamsModel {
    return this._requestParams as RequestParamsModel;
  }

  get config(): AnalysisStateConfig {
    // @ts-ignore
    return pick(this, ['datasetId', 'entityId', 'queryDataCollection', 'reportId', 'tileId', 'filterAggId']) as AnalysisStateConfig;
  }

  constructor(
    private analysisDataService: AnalysisDataService,
    private analysisEntityService: AnalysisEntityService,
    private analysisFiltersService: AnalysisFiltersService,
    private chartOptionsService: ChartOptionsService,
    private entitiesRepository: EntitiesRepositoryService,
    private environmentService: EnvironmentService,
    private filtersService: AnalysisFiltersService,
    private indicatorService: AnalysisIndicatorService,
    private metadataRepository: MetadataRepositoryService,
    private settingsRepository: SettingsRepositoryService,
    private userStorage: UserStorageService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private icTogglesNgService: IcTogglesNgService,
    private globalData: Globals,
    private dataRepositoryService: DataRepositoryService,
    private userService: UserService,
    private stateService: StateService
  ) {
    this.envConfig = this.environmentService.getEnvironment();
    this.unlockRank = this.envConfig.feature.unlockRank;
    if (this.analysisFiltersService.issnOrSource) {
      this.iSSNorSourceSubscription = this.analysisFiltersService.issnOrSource.subscribe((type) => {
        this.issnOrSource = type;
      });
    }
  }

  async getEsciFlag() {
    let esciResponse = await this.settingsRepository.getReportSettings('', 'settingNames=userEsciFlag').toPromise();
    if (esciResponse && Array.isArray(esciResponse)) {
      let esciSetting = {[esciResponse[0].settingName] : esciResponse[0].settingValues.toString()};
      this.userService.updateUserSettings(esciSetting);
    }
  }

  async init(stateParams: StateParams) {
    if (!this.globalData?.toggleData) {
      await this.setSplitIOToggles();
    } else {
      this.toggles = this.globalData.toggleData;
    }

    await this.getEsciFlag();


    this.setStateDefaults(stateParams);
    this.entities = await this.entitiesRepository.getList(this.datasetId).toPromise();
    this.currentEntity = this.entities.filter(entity => entity.id === this.entityId)[0];
    // @TODO Remove temporary QA2 debugging patch
    if (isUndefined(this.currentEntity)) {
      this.currentEntity = {
        id: 'department',
        title: 'Choose Department'
      };
    }

    if (this.reportId && this.tileId) {
      this.tile = await this.analysisDataService.getTile(this.config);
    } else {
      this.tile = undefined;
    }

    let state = this.tile ? this.getStateFromTile(this.tile) : this.getStateFromLocalStorage();
    if (!this.stateService.params.t) {
      state = this.getStateFromParams(this.setPreviousDatasetStateValues(state), stateParams);
    }
    if (state.selectedTabIndex) {
      this._selectedTabIndex = state.selectedTabIndex;
    }

    if (stateParams.selectedTab) {
      this._selectedTabIndex = parseInt(stateParams.selectedTab, 10);
    }
    state.datasetId = this.datasetId;
    this.chartSettings = (state.visualization || {}) as ChartSettingsModel;
    this.chartSettings.selectedDimensions = state.dimensions;

    const initialData = await this.getInitialData(this.config, state, this.tile).toPromise();
    if (!isUndefined(initialData)) {
      this.benchmarks = initialData.benchmarks;
      this.filters = initialData.filters;
      this.indicators = initialData.indicators;
      this.columnsIndicators = this.getColumnsIndicators(initialData.indicators.indicators, state);
      this.onRequestParamsChange(initialData.requestParams, false, true);
    }

    return this.state$;
  }

  setPreviousDatasetStateValues(currentState: SerializedExploreStateModel) {
    const previousDatasetState = cloneDeep(this.userStorage.getPreviousDatasetState(this.datasetId, this.entityId));
    if (previousDatasetState && this.envConfig.feature.retainAnalysisOnDatasetSwitch) {
      currentState.columnsIndicators = previousDatasetState.columnsIndicators || [];
      if (currentState.request) {
        currentState.request.filters = (previousDatasetState.request && previousDatasetState.request.filters) || {};
      } else {
        currentState.request = { filters :  (previousDatasetState.request && previousDatasetState.request.filters) || {}} as ExploreRequestModel;
      }

      currentState.request.indicators = previousDatasetState.request && previousDatasetState.request.indicators || [];
      currentState.benchmarks = previousDatasetState.benchmarks || [];
    }
    return currentState;
  }

  private setStateDefaults(stateParams: StateParams) {
    if (this.state$) {
      this.state$.complete();
    }
    this.state$ = new ReplaySubject<AnalysisStateModel>();

    this.exploreChildItems$ = of({}) as Observable<ExploreTileResponseModel>;
    this.exploreItems$ = of({}) as Observable<ExploreTileResponseModel>;
    this.benchmarksItems$ = of({}) as Observable<ExploreTileResponseModel>;
    this.groupsItems$ = of({}) as Observable<ExploreTileResponseModel>;
    this.visualizations$ = of([]) as Observable<VisualizationModel[]>;
    this.chartOptions$ = of({}) as Observable<ChartOptionsModel>;

    this.datasetId = stateParams.datasetId;
    this.entityId = stateParams.entityId;
    this.reportId = stateParams.reportId === '' ? undefined : stateParams.reportId;
    this.tileId = stateParams.tileId === '' ? undefined : stateParams.tileId;
    this.filterAggId = stateParams.filterAggId;
    this.queryDataCollection = stateParams.queryDataCollection || this.analysisDataService.getQueryDataCollection();
    this._requestParams = undefined;
  }

  private getInitialData(
    config: AnalysisStateConfig,
    state: SerializedExploreStateModel,
    tile?: ReportTileModel
  ): Observable<{
    benchmarks: AnalysisBenchmarkModel[];
    datasets: DatasetModel[];
    filters: AnalysisStateFiltersModel;
    indicators: AnalysisStateIndicatorsModel;
    requestParams: RequestParamsModel;
  }> {
    const noIndicator = this.envConfig.analysis.noIndicator.includes(config.entityId);

    return forkJoin([
      this.analysisDataService.getDatasets(config, tile),
      noIndicator ? of({}) : this.analysisDataService.getIndicators(config.entityId, state),
      this.analysisDataService.getFilters(config, state.request as RequestParamsModel, tile),
      this.dataRepositoryService.getCollections() // calling getCollections for all contexts
    ]).pipe(
      map((data: any) => { // tslint:disable-line:no-any
        this.datasets = data[0] as DatasetModel[];
        this.collection = data[3] as {};
        return {
          datasets: data[0] as DatasetModel[],
          indicators: data[1] as AnalysisStateIndicatorsModel,
          filters: data[2] as AnalysisStateFiltersModel,
          collections: data[3] as {}
        };
      }),
      tap((data) => {
        let indicatorLength = state && state.request && state.request.indicators && state.request.indicators.length || 0;
        if (indicatorLength) {
          state.request.indicators = this.analysisDataService.cleanupStateIndicatorsOrBenchmarks(state.request.indicators, data.indicators.indicators);
        }
        const previousTileDatasetState = cloneDeep(this.userStorage.getPreviousDatasetState(config.datasetId, config.entityId));
        const retainAnalysisOnDatasetSwitch = this.envConfig.feature.retainAnalysisOnDatasetSwitch;
        try {
          let stateValues = (tile && JSON.parse(tile.params).request && (!previousTileDatasetState || !retainAnalysisOnDatasetSwitch) ?
            JSON.parse(tile.params).request.filters : cloneDeep(state.request.filters)) || {};
          let filtersLength = stateValues && Object.keys(stateValues).length || 0;
          if (filtersLength) {
            state.request.filters = this.analysisDataService.cleanupStateFilters(stateValues, data.filters.filters);
          }
        } catch (error) {
          console.log(error);
        }
      }),
      map((data) => assign(data, {
        requestParams: this.getRequestParams(state, data.indicators, data.filters)
      })),
      mergeMap((data) => {
        if (noIndicator) {
          return of(assign(data, { benchmarks: [] }));
        }
        const hasGroupPinned = !isUndefined(data.requestParams.groupPinned);
        let compositeEntityKey = this.settingsRepository.getCompositeEntityKey(this.currentEntity.id,
        data.requestParams.filters, this.currentEntity.id === 'journal' ? this.issnOrSource : undefined);
        data.requestParams.indicators = this.getRequestParamIndicators(data.requestParams, data.indicators);
        this.exploreItems$ = this.analysisDataService.getExploreItems(this.exploreItems$, this.config, data.requestParams) as Observable<ExploreTileResponseModel>;
        this.groupsItems$ = this.analysisDataService.getGroupsItems(of({}) as Observable<ExploreTileResponseModel>, this.config, {
          ...data.requestParams,
          compositeEntityKey: compositeEntityKey
          // tslint:disable-next-line:no-any
        } as any as RequestParamsModel) as Observable<ExploreTileResponseModel>;

        return forkJoin([
          this.exploreItems$,
          this.analysisDataService.getBenchmarks(config, state),
          this.groupsItems$
        ]).pipe(
          // tslint:disable-next-line:no-any
          map((response: any) => {
            const benchmarks = this.disablePinnedBenchmarks(
              response[1],
              data.requestParams.pinned,
              [response[0], response[2]],
              hasGroupPinned ? data.requestParams.groupPinned! : []
            );
            return assign(data, {benchmarks});
          })
        );
      })
    );
  }

  /* Benchmarks */

  benchmarkToggle(benchmark: AnalysisBenchmarkModel) {
    benchmark.enabled = !benchmark.enabled;
    const toggleIndicators = [];
    if (benchmark && !benchmark.enabled) {
      if (benchmark.name === 'all' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllDocs)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllDocs);
      }
      if (benchmark.name === 'all' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllCites)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllCites);
      }
      if (benchmark.name === 'global' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseDocs)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseDocs);
      }
      if (benchmark.name === 'global' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseCites)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseCites);
      }
      if (benchmark.name === 'pinned' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedDocs)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedDocs);
      }
      if (benchmark.name === 'pinned' && this.isBaselineIndicator(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedCites)) {
        toggleIndicators.push(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedCites);
      }
    }

    this.indicatorToggle(toggleIndicators);
    this.benchmarks = clone(this.benchmarks);
    this.updateExploreAndBenchmarksItems(this.requestParams);
    this.updateChartOptions(this.requestParams, this.requestParams);
    this.state$.next(this.getAnalysisState());
  }

  /* Filters */

  esciToggle() {
    this.queryDataCollection = this.queryDataCollection === 'ESCI' ? undefined : 'ESCI';
      this.settingsRepository.save([{ 'settingName': 'userEsciFlag', 'settingValues': [!!this.queryDataCollection ? 'TRUE' : 'FALSE'] }]);
    this.requestParams.queryDataCollection = undefined;
    this.updateExploreAndBenchmarksItems(this.requestParams);
    this.updateChartOptions(this.requestParams, this.requestParams);
    this.state$.next(this.getAnalysisState());
  }

  private isBaselineIndicator(baselineIndicator: String) {
    return this.requestParams.indicators.find((ind) => ind === baselineIndicator);
  }

  omitOnApply(filter: AnalysisFilterModel) {
    return !this.envConfig.analysis.filters.omitOnApply[this.entityId].includes(filter.name);
  }

  async filterApply(event: AnalysisAppliedFiltersModel) {
    const filtersPromises = Object.keys(event).map((filterName) => {
      return this.analysisFiltersService.updateFilter(this.filters.filters, filterName, event);
    });

    await Promise.all(filtersPromises);

    this.filters.values = this.filtersService.getFiltersValues(this.filters.filters);
    const newRequestParamsFilters = this.updateRequestParamFilters(this.filters.values);
    const hiddenIndicatorNamesList = this.getHiddenIndicatorsList(newRequestParamsFilters);
    const newIndicatorsNames = this.indicatorService
      .getEnabledIndicatorsNames(this.indicators.indicators)
      .filter((i) => !hiddenIndicatorNamesList.includes(i));
    if (this.requestParams.citedRefocus) {
      this.requestParams.citedRefocus = false;
    }
    const newRequestParams = assign(
      clone(this.requestParams),
      {
        filters: newRequestParamsFilters,
        skip: 0,
        indicators: newIndicatorsNames
      }
    );

    this.onRequestParamsChange(newRequestParams);
  }

  async filtersRemove(filtersName: string[]) {
    const filters = filtersName.map((filterName) => find(this.filters.filters, {
      name: filterName
    }));
    let event = {};
    for (let filter of filters) {
      if (filter) {
        const defaults = this.filtersService.getFilterDefaults(filter);
        let options;
        if (!isEqual(filter.appliedValues, defaults) && filter.dependent && filter.type === 'option' && filter.dependent.length > 0) {
          for (const dependent of filter.dependent) {
            let dependentFilter = filters.find(filterObj => dependent === filterObj!.name);
            if (dependentFilter) {
              options = await this.analysisFiltersService.requestFilterOptions(dependentFilter.source, {
                [filter.name]: defaults.is
              } as  AnalysisFilterOptionParamsModel).toPromise();
              dependentFilter.options = options;
            }
          }
        }
        const dependsOnFilterExist = filter.dependsOn && filter.dependsOn.some((fltrName) => filtersName.includes(fltrName));
        if ((!dependsOnFilterExist && !isEqual(filter.appliedValues, defaults)) || (!isEqual(filter.appliedValues, defaults) && filtersName.length > 1)) {
          event = assign(event, {
            [filter.name]: defaults
          });
        }
      }
    }
    if (Object.keys(event).length > 0) {
      this.filterApply(event);
    }
    if (filtersName[0] === 'period') {
      this.filterDefaultValue.next(true);
    }
  }


  /* Indicators */

  indicatorToggle(names: string[]) {
    let indicators = this.indicators.indicators.filter((ind) => names.includes(ind.name));
    for (let i = 0; i < indicators.length; i++) {
      const linkedIndicators = indicators[i].linkedIndicators;
      const dependsOnIndicators = indicators[i].dependsOnIndicators;

      if (Array.isArray(linkedIndicators) && linkedIndicators.length > 0) {
          for (let j = 0; j < linkedIndicators.length; j++) {
           const existedIndicator = indicators.find((indicator) => indicator.name === linkedIndicators[j]);
            if (!existedIndicator) {
              const linked = this.indicators.indicators.find((indicator) => indicator.name === linkedIndicators[j]);
              linked!.enabled = indicators[i].enabled;
              indicators.push(linked as AnalysisIndicatorModel);
            }
          }
      }
      if (Array.isArray(dependsOnIndicators) && dependsOnIndicators.length > 0) {
        for (let j = 0; j < dependsOnIndicators.length; j++) {
         const existedIndicator = indicators.find((indicator) => indicator.name === dependsOnIndicators[j]);
         const dependsOnIndicator = this.indicators.indicators.find((indicator) => indicator.name === dependsOnIndicators[j]);
          if (!existedIndicator) {
            if (!indicators[i].enabled && !dependsOnIndicator!.enabled) {
              dependsOnIndicator!.enabled = indicators[i].enabled;
            indicators.push(dependsOnIndicator as AnalysisIndicatorModel);
          }
        }
    }
    }
    this.columnsIndicators.forEach((indicator) => {
      if (indicator.dependsOnIndicators) {
        const indicatorList = indicator.dependsOnIndicators;
        indicatorList.forEach((dependentIndicator) => {
          if (dependentIndicator === indicators[i].name) {
            indicator.enabled = indicators[i].enabled;
            indicators.push(indicator as AnalysisIndicatorModel);
          }
        });
      }
    });
  }
    if (indicators.length === 0 ) { return; }

    indicators.forEach(indObj => {
      indObj.enabled = !indObj.enabled;
      if (indObj.enabled ) {
        this.columnsIndicators.push(indObj);
      } else {
        const indicatorIndex = this.columnsIndicators.indexOf(indObj);

        this.columnsIndicators.splice(indicatorIndex, 1);
      }
    });

    const newIndicatorsNames = this.indicatorService.getEnabledIndicatorsNames(this.indicators.indicators);
    const sortColumnRemoved = this.requestParams.sortBy && !newIndicatorsNames.includes(this.requestParams.sortBy);
    const newRequestParams = assign(
      clone(this.requestParams),
      {
        indicators: newIndicatorsNames,
        skip: 0
      }
    );

    if (sortColumnRemoved) {
      newRequestParams.sortBy = this.analysisEntityService.getNamePropForEntityId(this.entityId);
    }

    this.onRequestParamsChange(newRequestParams);
  }

  moveTableColumns(name: string, direction: 'left'|'right') {
      this.columnsIndicators = this.moveIndicator(this.columnsIndicators, name, direction);
      this.columnReorder(this.columnsIndicators);
  }

  onColumnDrop(from: number, to: number) {
    if (to !== from) {
      this.columnsIndicators = this.dragIndicator(this.columnsIndicators, from, to);
      this.columnReorder(this.columnsIndicators);
    }
  }

  columnReorder(columnsIndicators: AnalysisIndicatorModel[]) {
    let indicatorsList = columnsIndicators.map(indicator => indicator.name);
    const indicatorsHidden = this._requestParams!.indicators.filter(item => !indicatorsList.includes(item));
    const indicatorsFinal = indicatorsList.concat(indicatorsHidden);
    this._requestParams!.indicators = indicatorsFinal;

    if (this.tile) {
      try {
        const params = JSON.parse(this.tile!.params);
        params.indicators = indicatorsFinal;
        this.tile!.params = JSON.stringify(params);
      } catch (error) {
        console.log(error);
      }
    }
    this.state$.next(this.getAnalysisState());
}

  sortTableColumns(event: SortableTableRowModel) {
    if (this.requestParams.sortBy !== event.sort.column.name || this.requestParams.sortOrder !== event.sort.direction) {
      const newRequestParams = assign(
        clone(this.requestParams),
        {
          sortBy: event.sort.column.name,
          sortOrder: event.sort.direction,
          skip: 0
        }
      );
      this.onRequestParamsChange(newRequestParams);
    }
  }

  /* Visualization */

  visualizationChange(changes: Partial<ChartSettingsModel>) {
    if (changes.itemsCount) {
      this.chartSettings.itemsCount = changes.itemsCount;
    }
    if (changes.type) {
      this.chartSettings.type = changes.type;
    }
    if (changes.zoomOptions) {
      this.chartSettings.zoomOptions = changes.zoomOptions;
    }
    if (changes.selectedDimensions) {
      this.chartSettings.selectedDimensions = changes.selectedDimensions;
    }
    if (['line chart', 'line amCharts', 'multiIndicatorLine chart', 'multiIndicatorLine amCharts'].includes(this.chartSettings.type)) {
      this.requestParams.extraType = 'no';
    }

    this.updateChartOptions(this.requestParams, this.requestParams);
    this.state$.next(this.getAnalysisState());
  }

  private updateExploreChildren(requestParams: RequestParamsModel) {
    this.exploreChildItems$ = this.analysisDataService.getExploreChildItems(this.config, requestParams);
  }

  private updateExploreAndBenchmarksItems(requestParams: RequestParamsModel, onPin: boolean = false, initialCall: boolean = false) {
    const hasGroupPinned = !isUndefined(requestParams.groupPinned);
    let compositeEntityKey = this.settingsRepository.getCompositeEntityKey(this.currentEntity.id,
      this.filters.values, this.currentEntity.id === 'journal' ? this.issnOrSource : undefined);

    if (onPin) {
      this.exploreItems$ = this.exploreItems$.pipe(
        map((data) => this.analysisDataService.markPinnedItems(data, requestParams.pinned)),
      ) as Observable<ExploreTileResponseModel>;

      this.groupsItems$ = this.groupsItems$.pipe(
        map((data) => this.analysisDataService.markPinnedItems(data, hasGroupPinned ? requestParams.groupPinned! : [])),
      ) as Observable<ExploreTileResponseModel>;
    } else {
      requestParams.indicators = this.getRequestParamIndicators(requestParams, this.indicators);

      if (!initialCall) {
        this.exploreItems$ = this.analysisDataService.getExploreItems(this.exploreItems$, this.config, requestParams) as Observable<ExploreTileResponseModel>;
        this.groupsItems$ = this.analysisDataService.getGroupsItems(of({}) as Observable<ExploreTileResponseModel>, this.config, {
          ...requestParams,
          compositeEntityKey: compositeEntityKey
          // tslint:disable-next-line:no-any
        } as any as RequestParamsModel) as Observable<ExploreTileResponseModel>;
      }
    }

    this.benchmarks = this.enableBenchmarksForBaselineShareIndicators(requestParams);
    const hasPinnedGroups = Array.isArray(requestParams.groupPinned) && requestParams.groupPinned.length > 0;
    this.benchmarksItems$ = this.analysisDataService.getBenchmarksItems(this.benchmarks, this.config,
      hasPinnedGroups ? {...requestParams, compositeEntityKey} : requestParams);
  }

  private enableBenchmarksForBaselineShareIndicators(requestParams: RequestParamsModel) {
    if (requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllDocs) > -1
        || requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntBaselineAllCites) > -1) {
        this.enableBenchmark('all');
    }
    if (requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseDocs) > -1
        || requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntGlobalBaseCites) > -1) {
        this.enableBenchmark('global');
    }
    if (requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedDocs) > -1
        || requestParams.indicators.indexOf(environmentCommonConfig.baselineShareIndicators.prcntBaselineForPinnedCites) > -1) {
        this.enableBenchmark('pinned');
    }
  return this.benchmarks;
}

  private enableBenchmark(benchmarkName: String) {
    this.benchmarks.forEach((benchmark) => {
      if (benchmark.name === benchmarkName) {
        benchmark.enabled = true;
      }
  });
  return this.benchmarks;
  }

  private getDataUpdateInfo(): Promise<string> {
    return this.metadataRepository.getDataUpdateInfo()
      .then((dataUpdateInfo) => {
        const exportDate = `Export Date: ${this.datePipe.transform((new Date()))}.`;
        const dateInfo = {
          deploydate: this.datePipe.transform(dataUpdateInfo.deploydate),
          wosextractdate: this.datePipe.transform(dataUpdateInfo.wosextractdate)
        };
        return this.translate.instant('analytics.explore.Data Update Info', dateInfo) + exportDate;
      });
  }

  updateChartOptions(requestParams: RequestParamsModel, oldRequestParams?: RequestParamsModel) {
    const {benchmarks, chartSettings, indicators, queryDataCollection, tile, visualizations$, exploreItems$, benchmarksItems$,
      config, dataUpdateInfo, datasetId, entityId, toggles} = this;
    const filtersApplied = this.filters;
    const datasetTitle = this.currentDataset ? this.currentDataset.title : '';

    this.chartOptions$ = this.chartOptionsService.getChartOptions({
      benchmarks,
      benchmarksItems$,
      chartSettings,
      exploreItems$,
      indicators,
      oldRequestParams,
      queryDataCollection,
      requestParams,
      tile,
      visualizations$,
      datasetTitle,
      filtersApplied,
      dataUpdateInfo,
      datasetId,
      entityId,
      toggles
    }, config).pipe(
      tap((data) => this.chartSettings.type = data.visualizationType)
    );
  }

  setSelectedTabIndex(id: number) {
    this._selectedTabIndex = id;
    this.saveAnalysisState();
  }

  async onExcludeOrInclude(event: {rowIds: string[], act: 'exclude'|'include'}) {
    const {rowIds, act} = event;
    let values: {[key: string]: {is?: string[], not?: string[]}} = await this.analysisDataService.getExcludeValues(
      rowIds,
      this.filters,
      this.config,
      this.requestParams,
      act
    );

    return this.filterApply(values);
  }

  saveAnalysisState() {
    if (!this.reportId && !this.tileId) {
      this.userStorage.saveExploreState(this.entityId, this.serialize());
    }
  }

  toggleSaveTile(filters: {[key: string]: AnalysisAppliedFilterValuesModel}) {
    if (isUndefined(this.envConfig.disableSaveTile)) return false;

    const disableSaveTile = this.envConfig.disableSaveTile;
    let foundDisablingFilter = false;

    for (let name of Object.keys(filters)) {
      if (isUndefined(disableSaveTile[name])) continue;
      const { is, not } = filters[name];
      const value = disableSaveTile[name].value;
      if (is === value || not === value) {
        foundDisablingFilter = true;
        break;
      }
    }

    return foundDisablingFilter;
  }

  hasChildren(row: SortableTableRowModel) {
    return !isUndefined(row.children) && row.children.length > 0;
  }

  setSchemaLevelForRequest(oldRequest: RequestParamsModel, request: RequestParamsModel, row: SortableTableRowModel) {
    if (isUndefined(row.schemalevel)) {
      let schemalevel = 'Micro';

      if (this.analysisDataService.isSchemaLevel(oldRequest, 'Macro')) {
        schemalevel = this.analysisDataService.isSchemaLevelMeso(oldRequest) ? 'Meso' : 'Macro';

        // tslint:disable-next-line:no-any
        request.filters.schemalevel = { is: schemalevel } as any as AnalysisAppliedFiltersModel;
      } else if (this.analysisDataService.isSchemaLevel(oldRequest, 'Meso')) {
        schemalevel = this.analysisDataService.isSchemaLevelMicro(oldRequest) ? 'Micro' : 'Meso';

        // tslint:disable-next-line:no-any
        request.filters.schemalevel = { is: schemalevel } as any as AnalysisAppliedFiltersModel;
      }
    } else {
      // tslint:disable-next-line:no-any
      request.filters.schemalevel = { is: row.schemalevel } as any as AnalysisAppliedFiltersModel;
    }
  }

  onLoadChildren(row: SortableTableRowModel): Observable<ExploreTileResponseModel> | undefined {
    if (this.hasChildren(row)) {
      row.showChildren = true;
      return;
    }
    if (
      typeof this.requestParams.filters.schema === 'undefined' ||
      typeof this.requestParams.filters.schemalevel === 'undefined' ||
      // tslint:disable-next-line:no-any
      (this.requestParams.filters.schema.is as any as string) !== 'Citation Topics'
      ||
      this.analysisDataService.isSchemaLevelMicro(this.requestParams)
    ) {
      return;
    }
    let childrenSubject = new Subject<ExploreTileResponseModel>();
    const oldRequestParams = cloneDeep(this.requestParams);
    const newRequestParams = assign(
      clone(this.requestParams),
      { skip: 0 },
    );
    // tslint:disable-next-line:no-any
    newRequestParams.filters.sbjname = { is: [row.data[0].value] as any };

    this.setSchemaLevelForRequest(oldRequestParams, newRequestParams, row);

    row.loadingChildren = true;
    let sbjsearch = {};
    if (newRequestParams.filters.sbjsearch) {
      sbjsearch = newRequestParams.filters.sbjsearch;
      delete newRequestParams.filters.sbjsearch;
    }
    this.onChildrenRequestParamsChange(newRequestParams);
    this.exploreChildItems$.subscribe(exploreResponse => {
      if (Object.keys(sbjsearch).length > 0) {
        newRequestParams.filters.sbjsearch = sbjsearch;
      } else {
        delete newRequestParams.filters.sbjsearch;
      }

      let amendedExploreResponse = cloneDeep(exploreResponse);

      if (this.analysisDataService.isSchemaLevelMeso(newRequestParams)) {
        amendedExploreResponse.items.forEach((child) => {
          child.children = [];
          child.loadingChildren = false;
          child.schemalevel = 'Meso';
          child.isExpandable = true;
          child.parentSchemaLevel = 'Macro';
        });
      } else if (this.analysisDataService.isSchemaLevelMicro(newRequestParams)) {
        amendedExploreResponse.items.forEach((child) => {
          child.children = [];
          child.loadingChildren = false;
          child.schemalevel = 'Micro';
          child.isExpandable = false;
          child.parentSchemaLevel = 'Meso';
        });
      }
      row.schemalevel = newRequestParams.filters.schemalevel.is;
      if (isUndefined(oldRequestParams.filters.sbjname)) {
        if (!isUndefined(this.requestParams.filters.sbjname)) {
          delete this.requestParams.filters.sbjname;
        }
      } else {
        this.requestParams.filters.sbjname = clone(oldRequestParams.filters.sbjname);
      }
      this.requestParams.filters.schemalevel = clone(oldRequestParams.filters.schemalevel);

      row.loadingChildren = false;
      childrenSubject.next(amendedExploreResponse);
    });
    return childrenSubject.asObservable();
  }

  onLoadMoreItems() {
    const newRequestParams = assign(
      clone(this.requestParams),
      {skip: this.requestParams.skip + this.requestParams.take}
    );

    this.onRequestParamsChange(newRequestParams);
  }

  onPinnedRows(ids: string[], groupIds: string[], addOrRemove: boolean) {
    const getNewPinned = (old: string[], incoming: string[]) => {
      if (addOrRemove) {
        return old.concat(incoming);
      } else {
        return old.filter((item) => !incoming.includes(item));
      }
    };
    const {pinned: oldPinned} = this.requestParams;
    const {groupPinned : oldPinnedGroups} = this.requestParams;
    const pinned = getNewPinned(oldPinned || [], ids);
    const groupPinned = getNewPinned(oldPinnedGroups || [], groupIds);

    forkJoin([this.exploreItems$, this.groupsItems$ as Observable<ExploreTileResponseModel>]).subscribe(exploreResponse => {
      this.benchmarks = this.disablePinnedBenchmarks(this.benchmarks, pinned, exploreResponse, groupPinned);
      const newRequestParams = assign(clone(this.requestParams), {pinned, groupPinned});
      this.onRequestParamsChange(newRequestParams);
    });
  }

  getTransitions() {
    return this.metadataRepository.transitions(this.datasetId, this.entityId, this.queryDataCollection!).pipe(
      map((data) => Array.isArray(data) ? data : []),
      shareReplay()
    );
  }
  getTransitionsForTimeCitedAndNonSelfTimescited(dataColumn: string) {
    return this.metadataRepository.transitionsForTimeCitedAndNonSelfTimescited(this.datasetId, this.entityId, this.queryDataCollection!, dataColumn).pipe(
      map((data) => Array.isArray(data) ? data : []),
      shareReplay()
    );
  }
  private async onChildrenRequestParamsChange(params: RequestParamsModel) {
    this.updateExploreChildren(params);
  }

  private async onRequestParamsChange(params: RequestParamsModel, saveState: boolean = true, initialCall: boolean = false) {
    const pinnedUpdated = this.requestParams && (!isEqual(this.requestParams.pinned, params.pinned) ||
      !isEqual(this.requestParams.groupPinned, params.groupPinned));
    this.visualizations$ = this.analysisDataService.getVisualizations(this.config, params);
    this.updateExploreAndBenchmarksItems(params, pinnedUpdated, initialCall);
    if (!this.dataUpdateInfo) {
      this.dataUpdateInfo = await this.getDataUpdateInfo();
    }
    this.updateChartOptions(params, this.requestParams);
    this._requestParams = params;
    this.benchmarks = clone(this.benchmarks);
    this.state$.next(this.getAnalysisState(saveState));
  }

  private getAnalysisState(saveState: boolean = true): AnalysisStateModel {
    if (saveState) {
      this.saveAnalysisState();
    }

    return {
      benchmarkItems$: this.benchmarksItems$,
      benchmarks: this.benchmarks,
      columnsIndicators: this.getColumnIndicators(),
      chartConfig$: this.chartOptions$,
      currentEntity: this.currentEntity,
      datasets: this.datasets,
      disableSaveTile: this.disableSaveTile,
      documentsTotal$: this.analysisDataService.getDocsTotal(this.config, this.requestParams),
      entities: this.entities,
      exploreChildItems$: this.exploreChildItems$,
      exploreItems$: this.exploreItems$,
      exploreItemsTotal$: this.analysisDataService.getItemsTotal(this.config, this.requestParams),
      filters: this.showRequiredFilters(clone(this.filters)),
      groupsItems$: this.groupsItems$,
      includeEsciDocuments: this.queryDataCollection === 'ESCI',
      indicators: this.filterIndicators(this.indicators, this.requestParams.filters),
      request: this.requestParams,
      search: '',
      selectedTabIndex: this._selectedTabIndex,
      tile: this.tile,
      visualizations$: this.getAvailableVisualizations(this.visualizations$, this.indicators.indicators),
      dataUpdateInfo: this.dataUpdateInfo
    };
  }

  public serialize() {
    return {
      benchmarks: this.benchmarks.filter((item) => item.enabled).map((item) => item.name),
      columnsIndicators: this.columnsIndicators.map((indicator) => indicator.name),
      datasetId: this.datasetId,
      dimensions: this.chartSettings.selectedDimensions,
      request: this.requestParams,
      selectedTabIndex: this._selectedTabIndex,
      visualization: this.chartSettings,
    };
  }

  private filterIndicators(
    stateIndicators: AnalysisStateIndicatorsModel,
    appliedFilters: {[index: string]: AnalysisAppliedFiltersModel},
  ): AnalysisStateIndicatorsModel {
    const clIndicators = cloneDeep(stateIndicators);
    const {indicators, groups: sourceGroups, primaryIndicator, primaryOrder, influencingFiltersNames} = clIndicators;
    const hiddenIndicatorNamesList = this.getHiddenIndicatorsList(appliedFilters);

    if (isUndefined(sourceGroups)) {
      return {} as AnalysisStateIndicatorsModel;
    }
    const groups = sourceGroups.map((group) => {
      if (group.indicators) {
        group.indicators = group.indicators.filter((i) => !hiddenIndicatorNamesList.includes(i.name));
      }
      return group;
    });

    return {indicators, groups, primaryIndicator, primaryOrder, influencingFiltersNames};
  }


  private showRequiredFilters(stateFilters: AnalysisStateFiltersModel) {
    const {values, filtersGroups, filters} = stateFilters;
    if (!filters.some(filter => !!filter.showOn)) return stateFilters;
    const filterGroupFilters = (filterList?: AnalysisFilterModel[]) => isArray(filterList) && filterList.forEach((fltr) => {
      let hidden = false;
      if (fltr.showOn) {
        const {showOn} = fltr;
        hidden = !(Object.keys(showOn).some((fltrName) => {
          // @ts-ignore
          return this.analysisFiltersService.isMatching(values, showOn, fltrName);
        }));
      }
      if (hidden) {
        // @ts-ignore
        let hiddenFiltersToUseInRequest = environmentCommonConfig.analysis.filters.useHiddenFiltersInRequest[this.currentEntity.id];
        if (!hiddenFiltersToUseInRequest || (hiddenFiltersToUseInRequest &&  !hiddenFiltersToUseInRequest.includes(fltr.name))) {
          delete values[fltr.name];
        }
      }
      fltr.hidden = hidden;
      if (environmentCommonConfig.hideFilterFromSidebar.includes(fltr.name)) {
        fltr.hidden = true;
      }
    });

    filtersGroups.forEach((group) => {
      filterGroupFilters(group.filters);
      if (group.filters && group.filters.length > 0 && group.filters.some(filter => filter.hidden !== true)) {
        group.hidden = false;
      } else {
        group.hidden = true;
      }
    });

    return stateFilters;
  }

  private getHiddenIndicatorsList(
    appliedFilters: {[index: string]: AnalysisAppliedFiltersModel},
  ): string[] {
    const {influencingFiltersNames} = this.indicators;
    const {filters} = this.filters;

    if (isUndefined(influencingFiltersNames)) {
      return [];
    }
    return influencingFiltersNames.reduce((acc, filterName) => {
      if (Object.keys(appliedFilters).includes(filterName)) {
        const value: any = appliedFilters[filterName].is || appliedFilters[filterName].not; // tslint:disable-line no-any
        const filter = filters.find((fltr) => fltr.name === filterName);
        const option = filter!.options!.find((opt) => opt.value === value);
        const indicatorsToHide: string[]|undefined = option && option.indicatosToHide as string[];

        if (indicatorsToHide) {
          indicatorsToHide.forEach(hiddenIndicator => {
            if (!acc.includes(hiddenIndicator)) {
              acc.push(hiddenIndicator);
            }
          });
        }
      }

      return acc;
    }, [] as string[]);
  }

  private getStateFromLocalStorage(): SerializedExploreStateModel {
    const defaultState = {
      visualization: {itemsCount: 5},
      request: {filters: {}, indicators: [], pinned: [], skip: 0, sortOrder: 'desc', take: 25, groupPinned: []}
    };
    const exploreState = this.userStorage.loadExploreState(this.entityId, this.datasetId);
    return exploreState && exploreState.datasetId === this.datasetId ? exploreState : defaultState;
  }

  getStateFromParams(currentState: SerializedExploreStateModel, stateParams: StateParams) {
    let filters: { [index: string]: Partial<FullFilterValueModel>; } = {};

    if (currentState && this.envConfig.analysis && this.envConfig.analysis.filterList) {
      if (currentState.request) {
        for (let stateParam in stateParams) {
          if (
            stateParams.hasOwnProperty(stateParam) &&
            !isUndefined(stateParams[stateParam]) &&
            stateParams[stateParam] !== null &&
            stateParams[stateParam].length > 0 &&
            !isUndefined(this.envConfig.analysis.filterList[stateParam])
          ) {
            const isParamArray = Array.isArray(stateParams[stateParam]);

            filters[stateParam] = {
              is: this.envConfig.analysis.filterList[stateParam].single ?
                isParamArray ?
                  stateParams[stateParam].join(',') :
                  stateParams[stateParam] :
                isParamArray ?
                  stateParams[stateParam] :
                  [stateParams[stateParam]]
            };
          }
        }
      }
    }
    if (Object.keys(filters).length > 0) {
      currentState.request.filters = filters;
    }
    return currentState;
  }

  private getStateFromTile(tile: ReportTileModel): SerializedExploreStateModel {
    try {
      let visualization = JSON.parse(tile.vis);
      const dimensions = visualization.dimensions;
      const params = JSON.parse(tile.params);
      const {request, benchmarks, datasetId, entity, queryDataCollection} = params;

      visualization = pick(visualization, ['zoomOptions', 'type', 'itemsCount']);
      return {visualization, request, benchmarks, datasetId, entity, queryDataCollection, dimensions , columnsIndicators : request.indicators} as SerializedExploreStateModel;
    } catch (error) {
      console.log(error);
      return {
        visualization: {},
        request: {},
        benchmarks: [],
        datasetId: '0',
        entity: '',
        queryDataCollection: undefined,
        dimensions: { value: [] },
        columnsIndicators: undefined
      } as unknown as SerializedExploreStateModel;
    }
  }

  private getRequestParams(
    state: SerializedExploreStateModel,
    indicators: AnalysisStateIndicatorsModel,
    filters: AnalysisStateFiltersModel,
  ) {
    const requestParams: RequestParamsModel = clone(state.request as RequestParamsModel);
    const stateIndicators = requestParams.indicators;
    const enabledIndicatorsNames = this.indicatorService.getEnabledIndicatorsNames(indicators.indicators);
    const defaultIndicatorsNames = stateIndicators && stateIndicators.length > 0 ? stateIndicators : enabledIndicatorsNames;
    const requiredIndicatorsNames = this.indicatorService.getRequiredIndicatorsNames(indicators.indicators);
    const sortByExist = (indicatorNames: string[], sortByIndicator: string, primarySortIndicator: string) => {
      if (indicatorNames.includes(sortByIndicator)) return sortByIndicator;
      if (indicatorNames.includes(primarySortIndicator)) return primarySortIndicator;
      return this.analysisEntityService.getNamePropForEntityId(this.entityId);
    };

    requestParams.filters = this.updateRequestParamFilters(filters.values);
    requestParams.indicators = union(defaultIndicatorsNames, requiredIndicatorsNames);
    requestParams.sortBy = sortByExist(requestParams.indicators, requestParams.sortBy, indicators.primaryIndicator);
    requestParams.sortOrder = requestParams.sortOrder || indicators.primaryOrder;
    requestParams.take = 25;
    requestParams.skip = 0;

    return requestParams;
  }

  private updateRequestParamFilters(values: { [index: string]: AnalysisAppliedFiltersModel }) {
    const currentDataset = this.currentDataset;
    if (currentDataset && (currentDataset.type === 'wid' || currentDataset.type === 'iid')) {
      return assign(values, { wpwid: { is: currentDataset.datasetId } });
    }
    return values;
  }

  private getAvailableVisualizations(
    visualizations$: Observable<VisualizationModel[]>,
    indicators: AnalysisIndicatorModel[]
  ): Observable<VisualizationModel[]> {
    const getAllIndicatorsList = (visualization: VisualizationModel): string[] => {
      const allIndicators = visualization.dimensions.reduce((acc, dimension) => {
        acc.push(...dimension.indicators);
        return acc;
      }, [] as string[]);

      return uniq(allIndicators);
    };
    const isIndicatorsAvailable = (visualization: VisualizationModel) => {
      const indicatorsNames = getAllIndicatorsList(visualization);
      const enabledIndicatorsNames = this.indicatorService.getEnabledIndicatorsNames(indicators);
      const includedVisIdicatorNames = indicatorsNames.filter((item) => enabledIndicatorsNames.includes(item));

      return includedVisIdicatorNames && includedVisIdicatorNames.length > 0;
    };

    return visualizations$.pipe(
      map((visualizations) => visualizations.filter((visualization) => isIndicatorsAvailable(visualization)))
    );
  }

  private disablePinnedBenchmarks(benchmarks: AnalysisBenchmarkModel[], pinned: string[], exploreItems: [ExploreTileResponseModel, ExploreTileResponseModel],
                                  groupsPinned: string[]) {
    return benchmarks.map((benchmark) => {
      const dependentItemOnly = () => {
        if (!benchmark.dependsOn) return false;
        let items = [];
        if (exploreItems[0] && Array.isArray(exploreItems[0].items) && exploreItems[0].items.length > 0) {
          let pinnedItems = exploreItems[0].items.filter(item => pinned.includes(item.key));
          // @ts-ignore
          items = pinnedItems.filter(item => item[benchmark.dependsOn]);
        }
        let groupItems = [];
        if (exploreItems[1] && Array.isArray(exploreItems[1].items) && exploreItems[1].items.length > 0) {
          let pinnedGroups = exploreItems[1].items.filter(item => groupsPinned.includes(item.key));
          // @ts-ignore
          groupItems = pinnedGroups.filter(item => item[benchmark.dependsOn]);
        }

        return !items.length && !groupItems.length;
      };
      if (benchmark.pinnedRequired) {
        benchmark.disabled = (isUndefined(pinned) && isUndefined(groupsPinned)) ||
          ((!isArray(pinned) || pinned.length === 0) && (!isArray(groupsPinned) || groupsPinned.length === 0)) || dependentItemOnly() as boolean;
      }

      return benchmark;
    });
  }

  private getColumnsIndicators(indicators: AnalysisIndicatorModel[], state: SerializedExploreStateModel) {
    const enabledIndicators = this.indicatorService.getEnabledIndicators(indicators);
    const columnsList = [] as AnalysisIndicatorModel[];
    const pickIndicators = (list: AnalysisIndicatorModel[], name: string) => {
      const indicator = enabledIndicators.find((ind) => ind.name === name);

      if (indicator) {
        list.push(indicator);
      }
      return list;
    };
    const reorderIndicators = (list: AnalysisIndicatorModel[], indicator: AnalysisIndicatorModel, index: number) => {
      if (indicator.name === 'rank' && index > 0) {
        list.splice(1, 0, indicator);
      } else {
        list.push(indicator);
      }
      return list;
    };
    const sortIndicators = (indicatorValues: []) => {
      // @ts-ignore
      let firstIndicators = environmentCommonConfig.firstColumnIndicators[this.currentEntity.id];
      if (firstIndicators && firstIndicators.length > 0) {
        let firstIndicatorsValues = indicatorValues.filter((indicator: AnalysisIndicatorModel) => firstIndicators.includes(indicator.name));
        // tslint:disable-next-line: max-line-length
        firstIndicatorsValues = firstIndicatorsValues.sort(function (a: { name: string }, b: { name: string }) { return firstIndicators.indexOf(a.name) - firstIndicators.indexOf(b.name); });
        return [...firstIndicatorsValues, ...indicatorValues.filter((indicator: AnalysisIndicatorModel) => !firstIndicators.includes(indicator.name))];
      } else {
        return indicatorValues;
      }
    };


    if (state && state.columnsIndicators && state.columnsIndicators.length > 2) {
      state.columnsIndicators = this.analysisDataService.cleanupStateIndicatorsOrBenchmarks(state.columnsIndicators, indicators);
      // @ts-ignore
      return sortIndicators(state.columnsIndicators.reduce(pickIndicators, columnsList));
    } else {
      if (this.unlockRank) {
        // @ts-ignore
        return sortIndicators(enabledIndicators.filter(indicator => !indicator.hidden));
      } else {
        return enabledIndicators.filter(indicator => !indicator.hidden).reduce(reorderIndicators, columnsList);
      }
    }
  }

  private moveIndicator(indicators: AnalysisIndicatorModel[], name: string, direction: 'left'|'right'): AnalysisIndicatorModel[] {
    const list = clone(indicators);
    const indicator = list.find((ind) => ind.name === name);
    const indicatorIndex = list.indexOf(indicator!);
    const moveIndex = direction === 'left' ? indicatorIndex - 1 : indicatorIndex + 1;

    list.splice(indicatorIndex, 1);
    list.splice(moveIndex, 0, indicator!);

    return list;
  }

  private dragIndicator(indicators: AnalysisIndicatorModel[], from: number, to: number) {
    const list = clone(indicators);
    list.splice(to, 0, list.splice(from, 1)[0]);
    return list;
  }

  shouldDisableRefocus(): boolean {
    const disableRefocusIndicator = ['wpclbrarea'];
    const appliedFilter = Object.keys(this.filters.values);
    return intersection(appliedFilter, disableRefocusIndicator).length > 0;
  }

  private getColumnIndicators() {
    const hiddenIndicatorNamesList = this.getHiddenIndicatorsList(this.filters.values);
    return this.columnsIndicators.filter((i) => {
      return this.requestParams.indicators.includes(i.name) && !hiddenIndicatorNamesList.includes(i.name);
    });
  }

  get columnIndicatorList() {
    return this.getColumnIndicators();
  }

  async setSplitIOToggles() {
    let toggles = await new Promise((resolve, reject) => {
      this.icTogglesNgService.toggles().subscribe((toggle: TogglesState) => {
        resolve(toggle);
      }, reject);
    }) as TogglesState;
    this.globalData.toggleData = toggles;
    this.toggles = this.globalData.toggleData;
  }

  getSplitIOToggles() {
    return this.toggles ? this.toggles : this.globalData.toggleData;
  }

  ngOnDestroy() {
    if (typeof this.iSSNorSourceSubscription !== 'undefined') {
      this.iSSNorSourceSubscription.unsubscribe();
    }
  }

  onJournalTypeChange() {
    this.onRequestParamsChange(this.requestParams, false);
  }
  getRequestParamIndicators(requestParams: RequestParamsModel, indicators: AnalysisStateIndicatorsModel) {
    const indicatorNames = indicators.indicators.map(value => value.name);
    let filteredIndicators = requestParams.indicators.filter(indicator => indicatorNames.includes(indicator));


    // @ts-ignore
    let isGIPP = requestParams.filters.schema && requestParams.filters.schema.is === 'GIPP';
    if (!isGIPP) {
      let reputedIndicators = indicators.indicators.filter(value => value.group && value.group === 'reputation');
      filteredIndicators = filteredIndicators.filter(item => !reputedIndicators.some(itemb => itemb.name === item));
    }
    return filteredIndicators;
  }
}
