import { FilterValueModel } from 'components/analytics/interfaces/filter.interface';
import { ReportTileModel } from './report-tile.model';

export interface ReportModel {
  empty?: boolean;
  folderId?: number|'root';
  hideFilter?: boolean;
  id: string;
  institutionProfile?: boolean;
  isOwners?: Function;
  isShared: Function;
  ljur?: boolean;
  loadingErrorMessage?: string;
  owner?: string;
  parentTabReportId?: string;
  put: Function;
  reportType: ReportTypes;
  safeTitle?: Function;
  selected?: boolean;
  subTitle?: string;
  system?: boolean;
  tiles: Array<ReportTileModel>;
  tilesAPI: {[index: string]: {[index: string]: Function}};
  title: string;
  typeOfReport: string;
  type?: string;
  wp?: boolean;
  wpdept?: boolean;
  annotation?: string;
  dashboard?: boolean;
  datasetId?: string;
}

export interface InstitutionReportModel {
  info: OrgInfo;
  report: ReportModel;
}

export interface OrgInfo {
  [index: string]: string;
  organisation_name: string;
  postal_address: string;
  twitterurl: string;
  country: string;
  linkedinurl: string;
  yearoffoundation: string;
  meta_id: string;
  dataset_id: string;
  web_address: string;
  organisation_id: string;
  facebookurl: string;
  waan_id: string;
  datasetid: string;
  phone_number: string;
  variationsoforganisation_name: string;
}

export interface ReportTileParamsModel {
  compositeEntityKey?: string;
  benchmarks: Array<string>;
  datasetId: string;
  entity: string;
  queryDataCollection: string;
  request: ReportTileRequestModel;
}

export interface ReportTileRequestModel {
  benchmarkNames?: Array<string>;
  citedOrCiting?: any; // tslint:disable-line: no-any
  extraType?: string;
  filters: {[index: string]: FilterValueModel};
  indicators: Array<string>;
  isSourceYear?: any; // tslint:disable-line: no-any
  pinned: Array<string>;
  groupPinned: Array<string>;
  skip: number;
  sortBy: string;
  sortOrder: 'asc'|'desc';
  take: number;
}

export interface ReportTileVisualisationModel {
  groupDataUrl?: string;
  benchmarksUrl?: string;
  dataLimited: boolean;
  dataUrl: string;
  dimensions: Object;
  id: string;
  itemsCount: number;
  series: {[index: string]: string};
  text: string;
  title: string;
  type: string;
}

export enum ReportTypes {
  department = 'iipDept',
  faculty = 'iipFaculty',
  organization = 'org',
  publisherRpt = 'publisherRpt',
  researcher = 'researcher',
  researcherTabRpt = 'researcherTabRpt',
  almaTabRpt = 'almaTabRpt',
  fundingRpt = 'fundingRpt'
}
