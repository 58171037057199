import { assign } from 'lodash';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSystemService, FileTypes } from 'components/common/services/file-system/file-system.service';
import { MetadataRepositoryService } from '../metadata-repository/metadata-repository.service';
import { DatePipe } from '@angular/common';
import { isFinite } from 'lodash';
import {Subject} from 'rxjs';
import {ExploreRequestModel} from 'components/common/interfaces/explore.request.model';
@Injectable()
export class ExportService {
  downloading: Subject<boolean> = new Subject<boolean>();
  excelTypes = ['Excel', 'xlsx'];

  constructor(
    private datePipe: DatePipe,
    private fsService: FileSystemService,
    private http: HttpClient,
    private metadataRepository: MetadataRepositoryService,
  ) {}

  async exportAnalysisDrilldown(
    params: {
      dataUrl: string,
      fileType?: string,

      currentSort: { sortBy: string , sortOrder: 'asc'|'desc'|undefined },
      fileName: string,
      indicatorName: string,
      request: object,
      take: number,
    }
  ) {
    const { dataUrl, fileType, currentSort, fileName, indicatorName, request, take } = params;
    const [ url, paramsString ] = this.getExportAnalysisDrilldownUrl({ dataUrl, fileType });
    const isExcel = this.isExcel(fileType);
    const preExistingPararams = paramsString.split('&').reduce((obj, paramString) => {
      const [key, value] = paramString.split('=');

      return assign(obj, {[key]: value});
    }, {});
    const requestUrl = this.getExportAnalysisRequestUrl(params, url);
    const urlParams = {
      ...preExistingPararams,
      ...currentSort,
      skip: '0',
      take: take.toString(),
      dataColumn: indicatorName,
      fileName,
    } as {[index: string]: string};
    const httpParams = new HttpParams({ fromObject: urlParams });
    const httpOptions: object = {
      headers: new HttpHeaders({
        'Content-Type': isExcel ? 'application/json' : 'application/x-www-form-urlencoded'
      }),
      params: httpParams,
      responseType: isExcel ? 'blob' : 'text',
    };
    const dataUpdateInfo = {
      dateInfo: {
        ...await this.metadataRepository.getDataUpdateInfo(),
        exportDate: this.datePipe.transform(new Date, 'yyyy-MM-dd'),
      }
    };
    const payload = isExcel ?
      assign(dataUpdateInfo, request) :
      this.transformRequest({ 'params': JSON.stringify(assign(dataUpdateInfo, request) )});
    const data = await this.http.post<string>(requestUrl, payload, httpOptions).toPromise();

    if (isExcel) {
      let exportLink = document.createElement('a');
      const blobUrl = exportLink.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
      exportLink.setAttribute( 'download', `${fileName}.xlsx`);
      document.body.appendChild(exportLink);
      exportLink.click();
      window.URL.revokeObjectURL(blobUrl);
    } else {
      const csv = this.formatCsv(data);
      this.fsService.saveTextFile(csv, FileTypes.csv, `${fileName}`);
    }
  }

  getExportAnalysisDrilldownUrl(params: {
    dataUrl: string,
    fileType?: string,
  }) {
    const { dataUrl, fileType } = params;
    const [ url, paramsString ] = dataUrl.split('?');

    if (this.isExcel(fileType)) {
      return [ url.replace('/incites-app', '/export'), paramsString ];
    }
    return [url, paramsString ];
  }


  isExcel(fileType?: string) {
    return fileType && this.excelTypes.includes(fileType);
  }

  async exportAnalysis(
    params: {
      datasetId: string,
      entityId: string,
      fileFormat: string,
      fileType?: string,
    },
    request:  ExploreRequestModel,
    currentSort: {sortBy: string, sortOrder: 'asc'|'desc'|undefined},
    fileName: string,
    take: number,
    queryDataCollection?: string,
    extraType?: string,
    exportType?: string,
  ) {
    const url = this.getExportAnalysisUrl(params);
    const isExcel = this.isExcel(params.fileType);

    if (request.filters.hasOwnProperty('jrnkey')) {
      delete request.filters.jrnkey;
    }
    if (Array.isArray(request.groupPinned) && request.groupPinned.length > 0) {
      request.groupPinned = request.groupPinned.map((groupId: string) => {
        if (groupId.includes('_')) {
          groupId = groupId.split('_')[1].split('_')[0];
        }
        return groupId;
      });
    }
    const requesUrl = this.getExportAnalysisRequestUrl(params, url, exportType);
    const urlParams = {
      ...currentSort,
      skip: '0',
      take: take.toString(),
      dataColumn: 'wosDocuments',
      fileName,
      queryDataCollection
    } as {[index: string]: string};

    const httpParams = new HttpParams({ fromObject: urlParams });
    const httpOptions: object = {
      headers: new HttpHeaders({
        'Content-Type': isExcel ? 'application/json' : 'application/x-www-form-urlencoded'
      }),
      params: httpParams,
      responseType: isExcel ? 'blob' : 'text',
    };

    const dataUpdateInfo = {
      dateInfo: {
        ...await this.metadataRepository.getDataUpdateInfo(),
        exportDate: this.datePipe.transform(new Date, 'yyyy-MM-dd')
      }
    };
    const payload = isExcel ?
      assign(dataUpdateInfo, request, extraType === 'fiveYearGroups' ? { extraType } : null) :
      this.transformRequest({
        'params': JSON.stringify(
          assign(dataUpdateInfo, request, extraType === 'fiveYearGroups' ? { extraType } : null)
        )
      });

    const data = await this.http.post<string>(requesUrl, payload, httpOptions).toPromise();

    if (isExcel) {
      let exportLink = document.createElement('a');
      const blobUrl = exportLink.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
      exportLink.setAttribute( 'download', `${fileName}.xlsx`);
      document.body.appendChild(exportLink);
      exportLink.click();
      window.URL.revokeObjectURL(blobUrl);
    } else {
      const csv = this.formatCsv(data);
      this.fsService.saveTextFile(csv, FileTypes.csv, `${fileName}`);
    }
  }

  getExportAnalysisUrl(params: {
    datasetId: string,
    entityId: string,
    fileFormat: string,
    fileType?: string,
  }) {
    const { datasetId, entityId, fileFormat, fileType } = params;
    if (this.isExcel(fileType)) {
      return `/export/explore/${datasetId}/${entityId}/data/${fileFormat}/page`;
    }
    return `/incites-app/explore/${datasetId}/${entityId}/data/${fileFormat}/page`;
  }

  getExportAnalysisRequestUrl(params: {
    fileType?: string,
  }, url: string, exportType?: string) {
    if (this.isExcel(params.fileType)) {
      return `${url}/${exportType === 'group' ? 'exportgroup' : 'export'}/xlsx`;
    }
    return `${url}/${exportType === 'group' ? 'exportgroup' : 'export'}/csv`;
  }

  formatCsv(csv: string) {
    let allRows = csv.split(/\r?\n|\r/);
    let formattedCSV = '';
    for (let singleRow = 0; singleRow < allRows.length; singleRow++) {
      const rowItems = allRows[singleRow].split(',');
      rowItems.forEach((item, index) => {
        if (item.includes('/') && isFinite(Number(item.split('/')[0])) &&
          isFinite(Number(item.split('/')[1]) )) {
          rowItems[index] = '="' + item + '"';
        }
      });
      formattedCSV += rowItems.join(',') + '\r\n';
    }

    return formattedCSV;
  }

  private transformRequest(obj: {[index: string]: string}) {
    const str: string[] = [];

    for (let p in obj) { // tslint:disable-line:forin
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }

    return str.join('&');
  }

  setDownloadLoader(value: boolean) {
    this.downloading.next(value);
  }

  // @ts-ignore
  async exportReportCSV(url: string, params: { [key: string]: string | number | null}) {
    let dataInfo = await this.metadataRepository.getDataUpdateInfo();
    params.exportDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      params.wosDate = this.datePipe.transform(dataInfo.wosextractdate, 'yyyy-MM-dd');
    params.deployDate = this.datePipe.transform(dataInfo.deploydate, 'yyyy-MM-dd');
    const httpOptions: object = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
      responseType: 'text',
      params: params
    };
    let csv = await this.http.get<string>(url, httpOptions).toPromise();
    csv = this.formatCsv(csv);
    this.fsService.saveTextFile(csv, FileTypes.csv, `${params.fileName}`);
  }
}
