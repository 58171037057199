import { Injectable } from '@angular/core';
import { FolderItemModel } from './models';
import { Observable } from 'rxjs';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { FolderWithItemsModel } from 'pages/my-saved/models/folder-with-items.model';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'components/shared/globalData';
import { EnvironmentService } from 'components/app/services/environment/environment.service';

const baseUrl: string = '/incites-app/';
const bnaBaseUrl: string = '/bna/';

@Injectable()
export class FoldersRepositoryService {

  private serviceUrl: string = `${baseUrl}user/folders`;
  private bnaMicroServiceUrl: string = `${bnaBaseUrl}user/folders`;
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  IS_MICRO_SERVICE_TOGGLE_ENABLED: boolean = false;

  constructor(
    private http: HttpClient,
    private globalData: Globals,
    private environmentService: EnvironmentService,
  ) {
    this.envConfig = this.environmentService.getEnvironment().splitioTreatment;
  }

  getSplitIOToggles(toggleValue: string): boolean {
    return (this.globalData?.toggleData && (this.globalData?.toggleData[toggleValue] === 'on'));
  }

  getList(): Promise<FolderModel[]> {
    return this.http.get<FolderModel[]>(this.getUrl())
      .toPromise();
  }

  getFoldersList(folderId?: number|string): Promise<FolderModel[]> {
    return this.http.get<FolderModel[]>(
      `${this.bnaMicroServiceUrl}/${folderId}`
    ).toPromise();
  }

  create(folder: Partial<FolderModel>): Promise<FolderModel> {
    return this.http.post<FolderModel>(
      this.getSplitIOToggles(this.envConfig.isMicroServiceForCreateFolderEnabled) ?
      this.bnaMicroServiceUrl : this.serviceUrl,
      folder)
      .toPromise();
  }

  save(folder: FolderModel) {
    const toggleFeature = this.getSplitIOToggles(this.envConfig.isMicroServiceForUpdateFolderEnabled);
    return this.http.post((toggleFeature ? this.bnaMicroServiceUrl : this.serviceUrl) + `/${folder.id}`, folder)
      .toPromise();
  }

  delete(folderId: number): Promise<{}> {
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForDeleteFolderEnabled))
      return this.http.delete(this.bnaMicroServiceUrl + `/${folderId}`, {}).toPromise();

    return this.http.post(this.serviceUrl + `/delete/${folderId}`, {}).toPromise();
  }

  getItemsList(folderId: number|string): Observable<FolderItemModel[]> {
    return this.http.get<FolderItemModel[]>(`${this.serviceUrl}/${folderId}/items`);
  }

  getItemsListPaginated(
    folderId: number|string,
    skip: number = 0,
    take: number = 25,
    sortBy: string = 'title',
    sortOrder: string = 'desc',
    search: string
  ): Observable<Partial<FolderWithItemsModel>> {
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceItemsEnable)) {
      return new Observable<Partial<FolderWithItemsModel>>((observer) => {
        this.http.get<FolderWithItemsModel>(`${this.bnaMicroServiceUrl}/${folderId}/items?itemType=AllItems&take=${take}&skip=${skip}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchParam=${search}`)
        .subscribe((items) => {
          const newItem = {
            items: items.items ? this.modifyItemsToOldDataModel(items.items, folderId) : items.items,
            total: items.total
          };
          observer.next(newItem);
          observer.complete();
        }, (e) => observer.error(e));
      });
    }
    return new Observable<FolderWithItemsModel>((observer) => {
      this.http.post<FolderWithItemsModel>(`${this.serviceUrl}/${folderId}/items`, {
        skip: skip,
        take: take,
        sortBy: sortBy,
        sortOrder: sortOrder
      }).subscribe((items) => {
        observer.next(items);
        observer.complete();
      });
    });


  }


  modifyItemsToOldDataModel(items: FolderItemModel[], folderId: number|string): FolderItemModel[] {
    let modifiedItems: FolderItemModel[];
    modifiedItems = items.map((item) => {
      switch (item.type) {
        case 'report':
          return {
            ...item,
            id: `dcr_${item.id}`,
            folderId
          };
        case 'dataset':
          return {
            ...item,
            datasetId: item?.metaData?.datasetId,
            id: `dcds_${item.id}`,
            folderId
          };
        case 'groups':
          let returnValue =  {
            ...item,
            entityType: item?.metaData?.entityType,
            compositeEntityKey: item?.metaData?.compositeEntityKey,
            name: item.title,
            folderId
          };
          return returnValue;
          case 'journalcollection':
            if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCollectionEnabled)) {
              const id = item.id;
              const { entityType, journalKeys, journalNames, sourceNameFilterConfig, issnFilterConfig, params } = item?.metaData;
              return {
                ...item,
                id,
                entityType,
                journalKeys,
                journalNames,
                sourceNameFilterConfig,
                issnFilterConfig,
                params,
                title: item.title,
                folderId
              };
            }
            return {
              ...item,
              id: `dbcoll_${item.id}`,
              entityType: item?.metaData.entityType,
              journalKeys: item?.metaData.journalKeys,
              journalNames: item?.metaData.journalNames,
              sourceNameFilterConfig: item?.metaData.sourceNameFilterConfig,
              issnFilterConfig: item?.metaData.issnFilterConfig,
              params: item?.metaData.params,
              title: item.title,
              folderId


            };
        default:
          return item;
      }
    });
    return modifiedItems;
  }

  moveItem(itemId: string, folderId: number|string) {
    itemId = itemId.replace(/[^0-9\.]+/g, '');
    return this.http.post(`${this.bnaMicroServiceUrl}/${folderId}/items/${itemId}`, null);
  }

  moveGroup(groupId: string, folderId: number|string) {
    return this.http.post(`${this.bnaMicroServiceUrl}/${folderId}/items/${groupId}`, null);
  }

  getSharedItemsList(): Observable<FolderItemModel[]> {
    return this.http.get<FolderItemModel[]>(`${baseUrl}user/shared`);
  }


  getUrl(): string {
    return this.bnaMicroServiceUrl;
  }
}