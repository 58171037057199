import { Injectable } from '@angular/core';

import { GroupDataModel, SettingsModel } from 'components/rest/interfaces/settings.model';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'components/auth/services/user/user.service';
import { AnalysisAppliedFiltersModel } from 'pages/analysis/models';
import { extend } from 'lodash';
import { ExploreRequestModel } from 'components/common/interfaces/explore.request.model';
import { AnalysisStateConfig } from 'pages/analysis/models/analysis-state.model';
import { ExploreTileResponseItemModel } from 'components/common/interfaces/exploretile.response.model';
import { UserStorageService } from 'components/analytics/services/user-storage/user-storage.service';
import { BulkMoveItemsModel } from 'pages/my-saved/models/bulk-move-items.model';
import { Globals } from 'components/shared/globalData';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { FolderItemModel } from '../folders-repository/models';
import { Subject } from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

const baseUrl: string = '/incites-app/';
const bnaBaseUrl: string = '/bna/';

@Injectable()
export class SettingsRepositoryService {

  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  listEntities: Subject<string> = new Subject<string>();
  departmentTableData: Subject<{[key: string]: string}[]> = new Subject<{[p: string]: string}[]>();
  constructor(
    private http: HttpClient,
    private user: UserService,
    private userStorageService: UserStorageService,
    private globalData: Globals,
    private environmentService: EnvironmentService,
  ) {
    this.envConfig = this.environmentService.getEnvironment().splitioTreatment;
  }

  getSplitIOToggles(toggleValue: string): boolean {
    return (this.globalData?.toggleData && (this.globalData?.toggleData[toggleValue] === 'on'));
  }

  save(settings: SettingsModel | any): Promise<{ [key: string]: any }> { // tslint:disable-line:no-any
    this.user.updateUserSettings(settings);
    if (settings[0].settingName === 'userOrganization') {
        return this.getOrgKey(settings.organization as string).pipe(mergeMap((orgKey) => {
          extend(settings, { orgKey });
          const userOrgKeySetting = {
            settingName: 'userOrgKey',
            settingValues: [orgKey],
          };
          return this.http.post(`${bnaBaseUrl}user/settings`, [...settings, userOrgKeySetting]);
        })).toPromise();
    } else {
        return this.http.post(`${bnaBaseUrl}user/settings`, settings).toPromise();
    }
  }

  getOrgKey(orgName: string) {
    return this.http.get(`${baseUrl}user/settings/org?orgName=${encodeURIComponent(orgName)}`);
  }

  getOrgName() {
    const url = `${baseUrl}explore/0/filter/almainstitutionfullname`; // tslint:disable-next-line:no-any
    return this.http.get<string>(url).pipe(map((response: any) => {
      return response[0];
    }));
  }

  getGroupSubtype(
    currentEntity: string,
    filterValues: { [index: string]: AnalysisAppliedFiltersModel }
  ) {
    // tslint:disable-next-line:no-any
    const subtypes: any = {
      person: {
        personIdType: {
          fullName: 'fullNameGroup',
          shortName: 'shortNameGroup',
          orcId: 'orcIdGroup',
          researcherId: 'researcherIdGroup',
          allUniqueId: 'allUniqueIdGroup',
          authorRecord: 'authorRecordGroup',
          claimed: 'claimedGroup',
          unclaimed: 'unclaimedGroup'
        }
      },
      subject: {
        schema: {
          'Web of Science': 'WOSGroup',
          'Essential Science Indicators': 'ESIGroup',
          'Sustainable Development Goals': 'SDGGroup',
          ANVUR: 'ANVURGroup',
          GIPP: 'GIPPGroup',
          'Australia FOR Level 1': 'AUS4Lvl1Group',
          'Australia FOR Level 2': 'AUS4Lvl2Group',
          'China SCADC Subject 97 Narrow': 'C-SCADC-Sub97NarrowGroup',
          'China SCADC Subject 13 Broad': 'C-SCADC-Sub13BroadGroup',
          'FAPESP': 'FAPESPGroup',
          'OECD': 'OECDGroup',
          'UK RAE (2008)': 'UK-RAE-2008Group',
          'UK REF (2014)': 'UK-RAE-2014Group',
          'UK REF (2021)': 'UK-RAE-2021Group',
          'KAKEN-L2 (Bunya2-H20) (10)': 'KAKEN-L2Group',
          'KAKEN-L3 (Bunka3-H20) (66)': 'KAKEN-L3Group',
          'CAPES (9)': 'CAPES9Group',
          'CAPES (49)': 'CAPES49Group',
          'CAPES (121)': 'CAPES121Group',
          RIS3: 'RIS3Group',
          PL19: 'PL19Group',
          ERC: 'ERCGroup',
        },
        schemalevel: {
          Macro: 'MacroGroup',
          Meso: 'MesoGroup',
          Micro: 'MicroGroup'
        }
      },
      funder: {
        funderType: {
          Funded: {
            'All Sources': 'Funded-AllSourcesGroup',
            'Funding text': 'Funded-FundingTextGroup',
            'Funding agency': 'Funded-FundingAgencyGroup',
          },
          Published: 'PublishedGroup',
          'Funded or Published': {
            'All Sources': 'FundedOrPublished-AllSourcesGroup',
            'Funding text': 'FundedOrPublished-FundingTextGroup',
            'Funding agency': 'FundedOrPublished-FundingAgencyGroup',
          }
        }
      },
      region: {
        locationType: {
          'Country/Region': 'CountryRegion',
          'Country Group': 'CountryGroup',
          'State/Province': 'State',
          'NUTS Level 1': 'NUTS1',
          'NUTS Level 2': 'NUTS2',
          'NUTS Level 3': 'NUTS3',
          'All': 'All',
        }
      }
    };

    let subtype = 'Group';
    if (subtypes[currentEntity]) {
      for (let subtypeKey in subtypes[currentEntity]) {
        if (filterValues[subtypeKey]) {
          // variable declaration to avoid maximum line length error
          let isPersonUniqueId: boolean = currentEntity === 'person' && filterValues[subtypeKey].is &&
            // tslint:disable-next-line:no-any
            filterValues[subtypeKey].is as any as string === 'uniqueId' && subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string];

          // edge case for funder & funderType
          // tslint:disable-next-line:no-any
          if (currentEntity === 'funder' && filterValues[subtypeKey].is && subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string]) {
            // tslint:disable-next-line:no-any
            if (filterValues[subtypeKey].is as any as string !== 'Published') {
              // tslint:disable-next-line:no-any
              let fundingDataSourceValue = filterValues['fundingDataSource'].is as any as string;
              // tslint:disable-next-line:no-any
              subtype = subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string][fundingDataSourceValue];
            } else { // FunderType = Funded or FunderType = Funded Or Published
              // tslint:disable-next-line:no-any
              subtype = subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string];
            }
            // tslint:disable-next-line:no-any
          } else if (isPersonUniqueId) {
            // tslint:disable-next-line:no-any
            let personIdTypeValue = filterValues['personIdType'].is as any as string;
            // tslint:disable-next-line:no-any
            subtype = subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string][personIdTypeValue];
            // tslint:disable-next-line:no-any
          } else if (filterValues[subtypeKey].is && subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string]) {
            // tslint:disable-next-line:no-any
            subtype = subtypes[currentEntity][subtypeKey][filterValues[subtypeKey].is as any as string];
          }
        }
      }
    }
    return subtype;
  }

  addCollection(
    collection: GroupDataModel,
    currentEntity: string,
    filterValues: { [index: string]: AnalysisAppliedFiltersModel },
    request: ExploreRequestModel,
    config: AnalysisStateConfig,
    issnOrSource: string | undefined
    // tslint:disable-next-line:no-any
  ): Promise<{ [key: string]: any }> {
    let compositeEntityKey = this.getCompositeEntityKey(currentEntity, filterValues, issnOrSource);
    // tslint:disable-next-line:no-any
    let body: any = {
      compositeEntityKey: compositeEntityKey,
      entityType: currentEntity,
      title: collection.name,
      isMyOrgData: false
    };

    if (collection.rows) {
      // tslint:disable-next-line:no-any
      body.journalKeys = (collection.rows as any).map((row: any) => row.key);
      // tslint:disable-next-line:no-any
      body.journalNames = (collection.rows as any).map((row: any) => row.jrnName);
    }
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCollectionEnabled)) {
      body.metaData = {issnOrSource, compositeEntityKey, isMyOrgData: body.isMyOrgData, datasetId: config.datasetId, queryDataCollection: config.queryDataCollection};
      delete body.compositeEntityKey;
      delete body.isMyOrgData;
      return this.http.post(`${bnaBaseUrl}user/collections/create`, body).toPromise();
    }
    body.params = { request, datasetId: config.datasetId, entity: config.entityId, queryDataCollection: config.queryDataCollection };
    return this.http.post(`${baseUrl}collections/create`, body).toPromise();
  }

  addGroup(
    group: GroupDataModel,
    currentEntity: string,
    filterValues: { [index: string]: AnalysisAppliedFiltersModel },
    request: ExploreRequestModel,
    config: AnalysisStateConfig,
    issnOrSource: string | undefined
  ): Promise<{ [key: string]: any }> { // tslint:disable-line:no-any

    // @ts-ignore
    if (currentEntity === 'subject' && filterValues.schema && filterValues.schema.is === 'Citation Topics' && filterValues.sbjname &&
      filterValues.sbjname.is) {
      let schemalevel = filterValues.schemalevel.is;
      // @ts-ignore
      if (schemalevel === 'Macro') {
        // @ts-ignore
        filterValues.schemalevel.is = request.filters.schemalevel.is = 'Meso';
        // @ts-ignore
      } else if (schemalevel === 'Meso') {
        // @ts-ignore
        filterValues.schemalevel.is = request.filters.schemalevel.is = 'Micro';
      }
    }

    // tslint:disable-next-line:no-any
    let body: any = {
      compositeEntityKey: this.getCompositeEntityKey(currentEntity, filterValues, issnOrSource),
      entityType: currentEntity,
      // groupType: 'fullName',
      name: group.name,
      isMyOrgData: false
    };

    if (group.rows) {
      // tslint:disable-next-line:no-any
      body.memberKeys = (group.rows as any).map((row: any) => row.key);
      // tslint:disable-next-line:no-any
      body.memberNames = (group.rows as any).map((row: any) => row.name);
    }

    body.params = { request, datasetId: config.datasetId, entity: config.entityId, queryDataCollection: config.queryDataCollection };
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCreateGroupEnabled)) {
      body = {
        ...body,
        myOrgData: body.isMyOrgData
      };
      body = this.user.currentUser?.esciFlag === 'TRUE' ? { ...body, queryDataCollection: 'ESCI' } : body;
      delete body.params;
      delete body.isMyOrgData;
      return this.http.post(`${bnaBaseUrl}user/groups/create`, body).toPromise();
    }

    return this.http.post(`${baseUrl}user/settings/groupData/create`, body).toPromise();
  }

  getCompositeEntityKey(
    currentEntity: string,
    filterValues: { [index: string]: AnalysisAppliedFiltersModel },
    issnOrSource: string | undefined
  ) {
    let subtype = '';
    if (currentEntity === 'journal' && issnOrSource) {
      subtype = issnOrSource + 'Group';
    } else {
      subtype = this.getGroupSubtype(currentEntity, filterValues);
    }

    return `${currentEntity}::${subtype}`;


  }

  // tslint:disable-next-line:no-any
  saveCollection(collection: GroupDataModel): Promise<any> {
    let body = {
      entityType: collection.entityType,
      title: collection.title,
      id: collection.id,
      compositeEntityKey: collection.compositeEntityKey,
    };
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCollectionEnabled)) {
      if (collection.edit) {
        delete body.compositeEntityKey;
        delete body.entityType;
        delete body.title;
        // @ts-ignore
        body.metaData = {issnFilterConfig: collection.metaData.issnFilterConfig};
        extend(body, { journalKeys: collection.journalKeys, journalNames: collection.journalNames });

        return this.http.post(`${bnaBaseUrl}user/collections/edit`, body).toPromise();

      } else {
        delete body.compositeEntityKey;
        delete body.entityType;

        return this.http.post(`${bnaBaseUrl}user/collections/edit`, body).toPromise();
      }
    }
    extend(body, { journalKeys: collection.journalKeys, journalNames: collection.journalNames });

    return this.http.post(`${baseUrl}collections/edit/${collection.id}`, body).toPromise();
  }


  // tslint:disable-next-line:no-any
  saveGroup(group: GroupDataModel, isTitleUpdate: boolean = false): Promise<any> {
    let body = {
      entityType: group.entityType,
      name: group.title ? group.title : group.name,
      id: group.grpId ? group.grpId : group.id,
      compositeEntityKey: group.compositeEntityKey,
    };
    if (group.edit) {
      extend(body, { memberKeys: group.memberKeys, memberNames: group.memberNames });
    }
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForGroupTitleUpdate) && group?.type === 'groups' && isTitleUpdate) {
      body = {
        ...group, ...body
      };
      return this.http.post(`${bnaBaseUrl}user/items/${body.id}/update`, { title: group.title }).toPromise();
    } else {
      body = {
        ...group, ...body
      };
      return this.http.post(`${bnaBaseUrl}user/groups/${body.id}/update`, body).toPromise();
    }
    return this.http.post(`${baseUrl}user/settings/groupData/edit`, body).toPromise();
  }

  // tslint:disable-next-line:no-any
  delete(group: GroupDataModel): Promise<any> {
    const body = {
      entityType: group.entityType,
      id: group.grpId ? group.grpId : group.id,
      compositeEntityKey: group.compositeEntityKey,
    };

    return this.http.delete(`${bnaBaseUrl}user/groups/${body.id}/delete`).toPromise();
  }

  // tslint:disable-next-line: no-any
  collectionDelete(collection: GroupDataModel): Promise<any> {
    if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCollectionEnabled)) {
      return this.http.delete(`${bnaBaseUrl}user/collections/delete/${collection.id}`, {}).toPromise();
    }
    return this.http.post(`${baseUrl}collections/delete/${collection.id}`, {}).toPromise();
  }

  getCombinedDataItems(groupItems: ExploreTileResponseItemModel[], exploreItems: ExploreTileResponseItemModel[], take: number, dataURl: string | undefined,
    compositeEntityKey?: string) {
    // To display combination of year wise data of the table items and the group items
    // for trend graphs based on the visualization

    const pinnedItems = exploreItems.filter((item) => item.pinned);
    const regularItems = exploreItems.filter((item) => !item.pinned);
    const pinnedGroupItems = groupItems.filter((item) => item.pinned);
    const regularGroupItems = groupItems.filter((item) => !item.pinned);

    let combinedItems = [];
    if (compositeEntityKey && this.userStorageService.getGroupHiddenState(compositeEntityKey as string)) {
      combinedItems = [...pinnedGroupItems, ...pinnedItems, ...regularItems];
    } else {
      combinedItems = [...pinnedGroupItems, ...pinnedItems, ...regularGroupItems, ...regularItems];
    }

    let itemKeys: string[] = [];
    return dataURl ? (combinedItems.filter((item) => {
      if (!itemKeys.includes(item.key)) {
        itemKeys.push(item.key);
      }
      return itemKeys.length <= take;
    })) : combinedItems.slice(0, take);
  }
  // tslint:disable-next-line: no-any
  bulkDelete(items: any) {
    return this.http.post(`${bnaBaseUrl}user/settings/bulk/delete`, items).toPromise();
  }
// tslint:disable-next-line: no-any
  bulkMove(items: BulkMoveItemsModel) {
    delete items.title;

    return this.http.post(`${bnaBaseUrl}user/settings/bulk/move`, items).toPromise();
  }
getAllGroupsInCurrentEntity(entity: string) {
   return this.http.get(`${bnaBaseUrl}user/groups/getGroupNames?entityName=${entity}`);
}

  getGroupInfo(documentId: number|string): Promise<FolderItemModel> {
    return this.http.get<FolderItemModel>(`${bnaBaseUrl}user/groups/info?documentId=${documentId}`).toPromise();
  }

  getReportSettings(reportType: string, params?: string) {
    if (reportType) {
      params = this.environmentService.getEnvironment().reportSettings[reportType].reduce((acc: string, value: string, index: number) =>
        acc = acc + `settingNames=${value}${index === this.environmentService.getEnvironment().reportSettings[reportType].length - 1 ? '' : '&'}`, '');
    }
    return this.http.get<[{settingName: string, settingValues: string | string[]}]>(`${bnaBaseUrl}user/settings?${params}`);
  }

  setDepartmentData(deptData: { [key: string]: string; }[]) {
    this.departmentTableData.next(deptData);
  }

}