import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ReportTileModel} from 'components/common/interfaces/report-tile.model';
import { OverlayModalComponent } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import {ReportModel} from 'components/common/interfaces/report.model';
import {ReportsRepositoryService} from 'components/rest/services/reports-repository/reports-repository.service';
import {OverbarService} from 'components/overbar/services/overbar/overbar.service';
import {TranslateService} from '@ngx-translate/core';
import { invalidCharacterTitleValidator } from 'pages/my-saved/components/saved-folders/saved-folders.validators';

@Component({
  selector: 'ic-tile-rename',
  templateUrl: './tile-rename.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileRenameComponent implements OnInit {

  @Input() tile!: Partial<ReportTileModel>;
  @ViewChild(OverlayModalComponent, { static: true }) overlayModal!: OverlayModalComponent;
  @Input() report!: Partial<ReportModel>;

  public renameForm: FormGroup;
  private titleMaxLength = 250;
  private subtitleMaxLength = 250;

  constructor(
    private reportsRepositoryService: ReportsRepositoryService,
    private overbarService: OverbarService,
    private tracker: EventsTrackerService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.renameForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleMaxLength),
        invalidCharacterTitleValidator.bind(this),
      ]),
      subtitle: new FormControl('', [
        Validators.maxLength(this.subtitleMaxLength)
      ])
    });
  }

  ngOnInit(): void {
  }

  openModal() {
    this.overlayModal.openModal();
  }

  onModalOpen() {
    this.renameForm.setValue({
      title: this.tile.title || '',
      subtitle: this.tile.subtitle || ''
    });
  }

  saveReport() {
    this.report.tiles!.forEach((tile, index) => {
      if (tile.id === this.tile.id) {
        this.report.tiles![index].title = this.renameForm.value.title;
        this.report.tiles![index].subtitle = this.renameForm.value.subtitle;
      }
    });

    this.reportsRepositoryService.save(this.report as ReportModel).then(() => {
      this.overlayModal.closeModal();
      this.cd.detectChanges();
      this.overbarService.notify(this.translate.instant('notifications.Tile renamed'));
      this.tracker.trackPageView();
    });
  }
}