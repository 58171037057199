export const environmentCommonConfig = {
  entity: ['department'],
  analysis: {
    filterList: {
      jrncountry: {
        single: false,
      },
    },
    filters: {
      dependsOn: {
        deptname: ['orgname'],
        departments: ['orgname'],
      },
      omitOnApply: { // omit these filters for these entity IDs
        department: ['orgname'],
      },
      hideOnChips: {
        department: ['departments', 'orgname']
      },
      omitDependents : {
        department : ['departments']
      },
      useHiddenFiltersInRequest : {
        department : ['deptHierarchy']
      }
    },
    noIndicator: [
    ],
    visualization: {
      disableChooseDifferentVisualization: [
        'mixedgraphtile',
        'publisherShares',
        'funderShares',
      ],
      max: {
        'wordCloud graph': 50,
      },
      overrideHideCounterFromApi: {
        'wordCloudAmcharts': false,
      },
      takeUseItemsCount: [
        'wordCloud graph',
      ],
    }
  },
  analysisFilterChoice: {
    choice: {
      link: 'link',
      range: 'range',
      multiple: 'search',
      required: 'select',
      single: 'radio',
      slider: 'slider',
      mixedRange: 'mixedRange',

    },
    default: 'checkbox',
    skipDefaultStateCheck: {
      slider: true,
    },
    type: {
      list: 'radio',
      boolean: 'checkbox'
    }
  },
  disableRoutesInProd: false,
  disableSaveTile: {
    clbrprsnIdTypeGroup: {
      value: 'authorRecord'
    },
    clbrprsnIdType: {
      value: 'authorRecord'
    },
    personIdTypeGroup: {
      value: 'authorRecord'
    },
    personIdType: {
      value: 'authorRecord'
    },
    assprsnIdTypeGroup: {
      value: 'authorRecord'
    },
    assprsnIdType: {
      value: 'authorRecord'
    },
    singlePrsnIdTypeGroup: {
      value: 'authorRecord'
    },
  },
  entityGroups: {
    maxGroupsPerType: 20,
    maxItemsInGroup: 100,
    maxItemsInCollection: 3000,
    toggleOffCollection: true
  },
  explore: {
    maxItems: 1000,
    minItems: 25,
    drilldownSkipLimit: 50000
  },
  export: {
    maxRecords: 50000,
    maxRecordsForTrendData: 251,
    maxGroupRecords: 20,
    maxGroupRecordsForTrendData: 20
  },
  splitioTreatment: {
    isMicroServiceItemsEnable: 'IC-31843-integrate_items_micro_service_end_point',
    isMicroServiceForMoveItemsEnabled: 'IC-32849-Micro_service_for_move_items_enabled',
    isMicroServiceForCreateFolderEnabled: 'IC-32839-Micro_service_for_create_folder',
    isMicroServiceForUpdateFolderEnabled: 'IC-32839-Micro_service_for_update_folder',
    isMicroServiceForDeleteFolderEnabled: 'IC-32841-Micro_Service_for_delete_folder',
    isMicroServiceForCreateGroupEnabled: 'IC-31932-Micro-service-for-create-group',
    refocusTimesCited: 'Refocus_for_timescited_indicator_IC-32075',
    isMicroServiceForGroupTitleUpdate: 'IC-34072-Micro_service_for_group_title_update',
    isRfNavLinkEnabled: 'RF-3139-RF_nav_link_enabled',
    newDateFormat: 'Publication_Date_NewDateFormat_Q3',
    isDeleteGroupEnabled: 'Delete_Group_Page',
    selfCitationRefocus: 'SelfCitationRefocus_IC-36933',
    preprintDatasetinReports: 'IC-36907_Ability_to_add_a_Preprint_Dataset_in_Reports',
    IC_38113_excel_download: 'IC_38113_excel_download',
    isShareDatasetSQSEnabled: 'IC_38596_share_dataset',
    isMicroServiceForCollectionEnabled: 'IC-33670_Micro-service-for-Collecton',
    displayCorrectFilterChip: 'IC-38991Ability_to_display_the_correct_Filter_Chips_for_Researchers',
    fiveYearTrendExcelExport: 'IC-39147_five-year-trend-excel-export',
    IC_39323_share_report: 'IC_39323_share_report',
    IC_39296_Author_Position_Indicators_Filters: 'IC_39296_Author_Position_Indicators_Filters'
   },
  amchartsLicenseKeys: ['CH267674409', 'MP267674409', 'TL267674409'],
  hideFilterFromSidebar: ['citedDocs'],
  baselineShareIndicators: {
    prcntBaselineAllDocs: 'prcntBaselineAllDocs',
    prcntBaselineAllCites: 'prcntBaselineAllCites',
    prcntGlobalBaseDocs: 'prcntGlobalBaseDocs',
    prcntGlobalBaseCites: 'prcntGlobalBaseCites',
    prcntBaselineForPinnedCites: 'prcntBaselineForPinnedCites',
    prcntBaselineForPinnedDocs: 'prcntBaselineForPinnedDocs',
    wosDocuments: 'wosDocuments',
    timesCited: 'timesCited'
  },
  pendo: {
    apiKey: '119121a4-cbc1-4be4-6e4e-976b210c1ff8',
  },
  SDG: 'Sustainable Development Goals',
  report: {
    filterList: {
      wppersonId: {
        default: 'Fearon, Nicolle',
        single: false,
        value: 'iipPersons',
        type: 'researcher'
      },
      wpdepts: {
        default: 'Clarivate University',
        single: false,
        value: 'iipOrganizations',
        type: 'iipDept'
      },
      orgname: {
        default: 'Drexel University',
        single: true,
        value: 'organization',
        type: 'org'
      },
      publisher: {
        default: ['Taylor & Francis (Unified)'],
        single: false,
        value: 'publishers',
        type: 'publisherRpt'
      },
      personId: {
        default: 'Jemal, Ahmedin: American Cancer Society',
        single: false,
        value: 'orcidRidValues',
        type: 'researcherTabRpt'
      }
    },
    links: {
      organization: {
        filter: 'orgname',
        indicator: 'orgName',
        type: 'org',
      },
      person: {
        filter: 'personId',
        indicator: 'prsnName',
        type: 'researcherTabRpt',
        dependsOnFilter: ['personIdType']
      }
    },
    alma: {
      listMax: 100
    }
  },
  filterMapping: {
    jrnSourceType: {
      'All': ['Journals', 'Books', 'Book Series'],
      'Books and Books series': ['Books', 'Book Series']
    }
  },
  hiddenDrillDownIndicators: ['hot_paper_docs', 'esi_most_cited_article', 'esi_bimonthly_hcp', 'esi_bimonthly_hp'],
  postFilterListValues: {
    departments: true
  },
  citationsFromPatents: 'citationsFromPatents',
  TimeCitedSelfCitation: 'nonselftimescited',
  reportSettings: {
    iipDept: ['userEsciFlag', 'userDeptStartYear', 'userDeptEndYear', 'userIIPOrganizations', 'userIipWid', 'iipWidName'],
    iipFaculty: ['userEsciFlag', 'userIIPPersons', 'userResearchReptDataset'],
    org: ['userEsciFlag', 'userOrganization', 'userOrgStartYear', 'userOrgEndYear', 'userResearchReptDataset'],
    publisherRpt: ['userEsciFlag', 'userPublishers', 'userPublisherStartYear', 'userPublisherEndYear', 'userPublisherOrganizations', 'userResearchReptDataset'],
    researcher: ['userEsciFlag', 'userIIPPersons', 'userResearchReptDataset'],
    researcherTabRpt: ['userEsciFlag', 'userIIPPersons', 'userResearchReptDataset', 'userOrcidRidType', 'userOrcidRidValues',
      'userResearcherStartYear', 'userResearcherEndYear', 'userResearchReptDataset'],
    almaTabRpt: ['userEsciFlag', 'userAlmaStartYear', 'userAlmaEndYear', 'userAlmaPublishers', 'userAlmaWosCategories', 'userAlmaSourceType',
      'userAlmaOrganization', 'userAlmaTakeCount'],
    fundingRpt: ['userEsciFlag', 'userFunders', 'userFunderStartYear', 'userFunderEndYear', 'userResearchReptDataset', 'userFunderOrganizations']
  },
  firstFilterGroups: {
    department: [
      'orgDeptList', 'orgType'
    ]
  },
  firstColumnIndicators: {
    department: [
      'deptName', 'parentOrgName'
    ]
  },
  serverErrorInterceptor: {
    exclude: {
      400: [
        '/bna/user/folders'
      ],
      404: [
        '/bna/user/folders'
      ]
    }
  },
  defaultFilterChips : {
    person: ['personIdTypeGroup', 'personIdType'],
    region: ['locationType'],
    subject: ['schemalevel'],
    funder: ['funderType', 'fundingDataSource']
  },
  tileService: {
    ignoredIndicatorKeys: [
      'hidden',
    ]
  },
};