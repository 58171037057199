<cl-overlay-modal [options]="{ 'modifiers': 'dark-header' }" class="ic-save-tile" (onOpen)="onModalOpen()">
  <cl-overlay-modal-content class="save-dialog" [modalTitle]="'report.tile.Rename' | translate">
    <form class="cl-form" [formGroup]="renameForm" (ngSubmit)="saveReport()">
      <fieldset class="cl-form__fieldset cl-form__fieldset--stacked">
        <legend class="visually-hidden">{{ 'report.tile.Rename' | translate }}</legend>
        <div class="cl-form__field">
          <label class="cl-form-label">
            {{ 'analytics.explore.tile.Title' | translate }}
            <input class="cl-form-text title" type="text" formControlName="title"/>
          </label>
        </div>

        <div class="cl-form__field ic-save-tile__subtitle">
          <label class="cl-form-label">
            {{ 'analytics.explore.tile.Subtitle' | translate }}
            <input class="cl-form-text subtitle" type="text" formControlName="subtitle"/>
          </label>
        </div>
        <div class="cl-form__actions">
          <button type="submit"
                  class="cl-btn cl-btn--primary"
                  [disabled]="!renameForm.valid"
          >
            {{ 'analytics.explore.tile.Save' | translate }}
          </button>
        </div>
      </fieldset>
    </form>
  </cl-overlay-modal-content>
</cl-overlay-modal>