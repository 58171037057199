import { EventEmitter, Injectable, Output } from '@angular/core';
import { DatasetModel } from './models';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatasetsUploadLimit } from './models/dataset.model';
import { omitBy, isNil } from 'lodash';
import { Globals } from 'components/shared/globalData';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import { TogglesState } from '@wos/toggles-core';
import { EnvironmentService } from 'components/app/services/environment/environment.service';


const baseUrl: string = '/incites-app/';

@Injectable()
export class DatasetsRepositoryService {
  private serviceUrl: string = `${baseUrl}datasets`;
  @Output() isDownloading = new EventEmitter();
  // tslint:disable-next-line:no-any
  env: { [key: string]: any; };

  constructor(
    private environmentService: EnvironmentService,
    private globalData: Globals,
    private http: HttpClient,
    private icTogglesNgService: IcTogglesNgService,
  ) {
    this.env = this.environmentService.getEnvironment();
    this.setToggles();
  }

  async setToggles() {
    if (!this.globalData.toggleData) {
      this.globalData.toggleData = await new Promise((resolve, reject) => {
        this.icTogglesNgService.toggles().subscribe((toggle: TogglesState) => {
          resolve(toggle);
        }, reject);
      }) as TogglesState;
    }
  }

  getListRx(params?: { [index: string]: string }): Observable<DatasetModel[]> {
    if (params && params.filterAggId && params.filterAggId === 'undefined') {
      delete params.filterAggId;
    }

    return this.http.get<DatasetModel[]>(
      this.serviceUrl,
      { params: new HttpParams({ fromObject: omitBy(params, isNil) }) }
    );
  }

  /**
   * Get a list of datasets
   * @param  {Object} params - You can use the following params:
   *      1) {String} params.datasetId - The external identifier of the dataset
   *      2) {String} params.owner - The owner of the dataset
   * @return {Promise}
   */
  getList(params?: { [index: string]: string }): Promise<DatasetModel[]> {
    return this.getListRx(params).toPromise();
  }

  get(id: string): Promise<DatasetModel> {
    return this.http.get<DatasetModel>(`${this.serviceUrl}/${id}`)
      .toPromise();
  }

  save(dataset: DatasetModel) {
    return this.http.post(`${this.serviceUrl}/${dataset.id}`, dataset)
      .toPromise();
  }

  share(dataset: { emails: string, message: string, type: string }, id: string) {
    const IS_SHARE_DATASET_SQS_ENABLED = this.env.splitioTreatment.isShareDatasetSQSEnabled;

    if (this.globalData?.toggleData[IS_SHARE_DATASET_SQS_ENABLED] === 'on') {
      return this.http.post(`${baseUrl}share/dataset/${id}`, {
        ...dataset,
        url: location.toString(),
      }).toPromise();
    } else {
      return this.http.post(`${this.serviceUrl}/share/${id}`, dataset)
        .toPromise();
    }
  }

  delete(id: string) {
    return this.http.post(`${this.serviceUrl}/delete/${id}`, {})
      .toPromise();
  }

  uploadDatasetsLimit(): Promise<DatasetsUploadLimit> {
    return this.http.get<DatasetsUploadLimit>(`${this.serviceUrl}/userDatasetStatus`)
      .toPromise();
  }

  uploadFile(fileFormData: FormData, fileName: string) {

    return this.http.post<Array<string>>(`${baseUrl}upload/userInputDataset?datasetName=${fileName}`, fileFormData)
      .toPromise();
  }

  exportDataSet(params: string, datasetId: string, fileName: string) {
    const body = new HttpParams({
      fromObject: {
        params
      }
    });

    const queryParams = new HttpParams();
    queryParams.set('datasetId', datasetId);
    queryParams.set('fileName', fileName);

    return this.http.post(`${this.serviceUrl}/export/csv?datasetId=${datasetId}&fileName=${fileName}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      responseType: 'text'
    }).toPromise();
  }
  preventDownload(downloading: boolean) {
    this.isDownloading.emit(downloading);
  }
}